import React, { useEffect } from 'react';

export const Accesibilidad = () => {

useEffect(() => {
	document.title = "Accesibilidad";
}, []);

return (
	<>
	<main>
		<div className="col-12 intsection accesibilidad">
		<h1 className='titularmapaweb'>Accesibilidad</h1>
		<div className="col-12">
			
			La Universidad de Jaén se ha comprometido a hacer accesibles sus sitios web, de conformidad con el Real Decreto 1112/2018, de 7 de septiembre, sobre accesibilidad de los sitios web y aplicaciones para dispositivos móviles del sector público.
			La presente declaración de accesibilidad se aplica al sitio
			
		</div>
		<div class="col-12">
		<ul>	
				<li><a href="https://formacion.ujaen.es" target="_blank" rel="noreferrer">https://formacion.ujaen.es</a></li>				
			</ul>
		</div>
		<div class="col-12">
				<h2>Situación de cumplimiento</h2>

				Este sitio web es parcialmente conforme con el RD 1112/2018 de 7 de septiembre, debido a las excepciones y a la falta de conformidad de los aspectos que se indican a continuación.
				Contenido no accesible
		</div>
		<div class="col-12">
				
				<h2>El contenido que se recoge a continuación no es accesible por lo siguiente:</h2>

				a) falta de conformidad con:
		</div>
		<div class="col-12">
		
			<ul>				
				<li>Imágenes que pueden requerir descripción [requisito número 1.1.1-Contenido no textual].</li>
				<li>Generación de contenido desde las hojas de estilo [1.3.1-Información y relaciones].</li>
				<li>Posicionamiento de elementos mediante flotado y posicionamiento de elementos de forma absoluta [1.3.2-Secuencia con significado].</li>
				<li>Características sensoriales [1.3.3-Características sensoriales].</li>
				<li>Información mediante color [1.4.1-Uso del color].</li>
				<li>Contraste para fuentes grandes, contraste insuficiente entre el color de primer plano y el color de fondo, contraste [1.4.3-Contraste (Mínimo)].</li>
				<li>Utilización de tamaños de fuente absolutosAyuda, utilización de medidas absolutas en elementos de bloque [1.4.4-Redimensionamiento del texto].</li>
				<li>Imágenes susceptibles de ser sustituidas por marcado[1.4.5-Imágenes de texto].</li>
				<li>Movimiento automático del foco [2.1.1-Teclado].</li>
				<li>Movimiento del foco mediante teclado [2.1.2-Sin bloqueos de teclado].</li>
				<li>Límite de tiempo de sesión, limite de tiempo controlado mediante un script, lectura de textos en movimiento [2.2.1-Tiempo ajustable].</li>
				<li>Utilización de la propiedad 'text-decoration: blink', contenido en movimiento o parpadeante[2.2.2-Pausar, detener, ocultar].</li>
				<li>Destellos por debajo del umbral límite. [2.3.1-Umbral de tres destellos o menos].</li>
				<li>Saltar bloques de contenido repetidos, bloques de contenido [2.4.1-Evitar bloques].</li>
				<li>Página con título descriptivo [2.4.2-Páginas tituladas].</li>
				<li>Orden lógico de navegación, Posicionamiento de elementos mediante flotado [2.4.3-Orden del foco].</li>
				<li>Múltiples médios de localización [2.4.5-Múltiples vías].</li>
				<li>Contenido adecuado de encabezados y etiquetas [2.4.6-Encabezados y etiquetas].</li>
				<li>Cambio de foco con el evento 'onfocus', utilización de la seudoclase :focus [2.4.7-Foco visible].</li>
				<li>Cambios en el idioma [3.1.2-Idioma de las partes].</li>
				<li>Cambio de contenidos con el evento 'onfocus', cambios inesperados del foco en el evento 'onfocus', apertura de ventana al cambiar el foco, apertura de ventana con el evento 'onload', cambio de contenidos con el evento 'onfocus' [3.2.1-Al recibir el foco].</li>
				<li>Cambios causados por el evento 'onChange' en un selector [3.2.2-Al introducir datos].</li>
				<li>Navegación consistente [3.2.3-Navegación consistente].</li>
				<li>Denominación consistente [3.2.4-Identificación consistente].</li>
				<li>Nombre, rol y valorAyuda [ 4.1.2-Nombre, función, valor].</li>
			</ul>
		</div>
{/* 
		<div class="col-12">

		<h2>Preparación de la presente declaración de accesibilidad</h2>

			La presente declaración fue preparada el 27 de julio de 2023.

			El método empleado para preparar la declaración ha sido una evaluación llevada a cabo por el propio organismo.

			Última revisión de la declaración: 27 de julio de 2023.
		</div>
		<div class="col-12">
		Observaciones y datos de contacto

		Puede realizar comunicaciones sobre requisitos de accesibilidad (artículo 10.2.a del RD 1112/2018), como por ejemplo:
		<ul className="sep15top">
			<li>informar sobre cualquier posible incumplimiento por parte de este sitio web</li>
			<li>transmitir otras dificultades de acceso al contenido</li>
			<li>formular cualquier otra consulta o sugerencia de mejora relativa a la accesibilidad del sitio web</li>
		</ul>
		A través de la Instancia/Solicitud General, llamando al teléfono (+34) 953 212 121 o presencialmente en el Servicio de Información, Registro y Administración Electrónico (Campus Las Lagunillas, s/n; Edificio Rectorado (B1); 23071. Jaén; Dependencias B1-047)

		Puede presentar:

		<ul className="sep15top">
		<li>una Queja relativa al cumplimiento de los requisitos del RD 1112/2018 o</li>
    	<li>una Solicitud de Información accesible relativa a:</li>
			<ul>
			<li>contenidos que están excluidos del ámbito de aplicación del RD 1112/2018 según lo establecido por el artículo 3, apartado 4</li>
			<li>contenidos que están exentos del cumplimiento de los requisitos de accesibilidad por imponer una carga desproporcionada.</li>
			</ul>
		</ul>	
			En la Solicitud de información accesible, se debe concretar, con toda claridad, los hechos, razones y petición que permitan constatar que se trata de una solicitud razonable y legítima.

			A través de la Instancia/Solicitud General, así como del resto de opciones recogidas en la Ley 39/ 2015, de 1 de octubre, del Procedimiento Administrativo Común de las Administraciones Públicas.

			Las comunicaciones serán recibidas y tramitadas por el Servicio de Información, Registro y Administración Electrónica (SIR@).
		</div>
 */}
		<div class="col-12">
			<h2>Procedimiento de aplicación</h2>

			Si una vez realizada una solicitud de información accesible o queja, ésta hubiera sido desestimada, no se estuviera de acuerdo con la decisión adoptada, o la respuesta no cumpliera los requisitos contemplados en el artículo 12.5, la persona interesada podrá iniciar una reclamación, igualmente se podrá iniciar una reclamación, igualmente se podrá iniciar una reclamación, igualmente se podrá iniciar una reclamación en el caso de que haya transcurrido el plazo de veinte días hábiles sin haber obtenido respuesta.

			La reclamación puede ser presentada a través de la Instancia/Solicitud General, así como con el resto de opciones recogidas en la Ley 39/2015, de 1 de octubre, del Procedimiento Administrativo de las Administraciones Públicas.

			Las reclamaciones serán recibidas y tramitadas por el Servicio de Información, Registro y Administración Electrónica (SIR@).
					
		</div>
		<div class="col-12">
			<h2>Contenido opcional</h2>

			Este sitio web aplica los requisitos de la Norma UNE-EN 301549:2019 considerando las excepciones del RD 1112/2018.

			Navegación con el teclado

			A continuación se describen las combinaciones de teclas para navegar por el contenido con el teclado:
	
		</div>
		<div class="col-12">
			<ul>
			<li>Tab: siguiente enlace, entrada de menú o control de formulario.</li>
			<li>Shift + Tab: anterior enlace, entrada de menú, o control de formulario.</li>
			<li>Spacebar: despliega/pliega menús, activa casillas de verificación.</li>
			<li>Flechas: selecciona un botón "radio", una opción de una lista, etc...</li>
			<li>Enter: activa el enlace o botón.</li>
			</ul>
		</div>
	
		</div>
	</main>
	</>
)
}