import React, { useEffect, useContext } from 'react';
import { Link, useParams } from 'react-router-dom'
//CONTEXT
import ConexContext from '../context/ConexContext'

export const MapaWeb = () => {

	const { lang } = useParams();//parametros de la url
	const { setLocale} = useContext(ConexContext);//contexto

useEffect(() => {
	document.title = "mapa web";
}, []);

/**
 * Cambia el idioma de la página
 */
useEffect(() => {
	setLocale(lang);
}, [lang, setLocale]);

return (
	<>
	<main>
		<div className="col-12 intsection mapaweb">
		<h1 className='titularmapaweb'>Mapa web</h1>
		<div className="col-12">
				<ul className=''>
				<li><Link to={{ pathname: `/${lang}/Inicio` }}>Inicio</Link></li>
				<li>Formación</li>
				<ul>
					<li><Link to={{ pathname: `/${lang}/Estudiantes` }}>Estudiantes</Link></li>
					<li><Link to={{ pathname: `/${lang}/PDI` }}>PDI</Link></li>
					<li><Link to={{ pathname: `/${lang}/PTGAS` }}>PTGAS</Link></li>
					<li><Link to={{ pathname: `/${lang}/Empresas` }}>Empresas</Link></li>
					<li><Link to={{ pathname: `/${lang}/Microcredenciales` }}>Microcredenciales</Link></li>
				</ul>
				<li><Link to={{ pathname: `/${lang}/innovacion` }}>Innovación docente</Link></li>
				<li><Link to={{ pathname: `/${lang}/tecnologias` }}>Tecnologías Educativas</Link></li>
				<ul>
					<li><Link to={{ pathname: `/${lang}/EdPuzzle` }}>EdPuzzle</Link></li>
				</ul>
				</ul>
		</div>
		</div>
	</main>
	</>
)
}


