import React, { useContext, useEffect } from 'react'
import { Link, useParams } from 'react-router-dom';

import robot from '../assets/img/robot.png';



//CONTEXT
import ConexContext from '../context/ConexContext'

export const Error = () => {
	const { lang } = useParams();//parametros de la url
	const { loc }=useContext(ConexContext)//contexto
	
	useEffect(() => {
		document.title = "Error 404";
	  }, []);

	return (
		<>    
			<div className="error">
				<div className='col-12 volver'>
					<div className='col-12 contvolver'>
						<Link to={{pathname:`/${lang}/inicio`}} alt="volver al inicio">
							{loc("Volver")}
						</Link>
					</div>
						
				</div>
				
			</div>

			<nav aria-label="breadcrumb " className="migapan marron error">
				<ol className="breadcrumb">
					<li className="breadcrumb-item">
						<Link to={{pathname:`/${lang}/inicio`}}>
							{loc("NAVBAR-INICIO")}
						</Link>
					</li>
					<li className="breadcrumb-item active" aria-current="page">{loc("error")}</li>
				</ol>
			</nav>

            <div className="col-12 intsection ampliado  error2">
                <img src={robot} alt="página no encontrada" title="página no encontrada"/>
				<h2>Error 404</h2>
                <h3>Página no encontrada</h3>
                <h4>Lo sentimos, no hemos podido encontrar la página que buscas</h4>
                <div className="col-12 centermid">
                    {/* <a href="" alt="Ir a la página de inicio" >Volver a la página de inicio</a>      */}
										<Link to={{pathname:`/${lang}/inicio`}} alt="Volver a la página de inicio">
											{loc("Volver a la página de inicio")}
										</Link>
                </div>
            </div>
			
		</>
	)
}
