/**
 *Funcion que formatea un texto en fecha: 
 * si la fecha no tiene formato válido devuelve null
 * formatos permitidos para orig y dest:
 * esp (dd/mm/aaaa), jav (objeto de javascript), ISO8601 (yyyymmdd), ing (yyyy-mm-dd) 
 * object (day:number month:number year:number) y ingL(yyyy-mm-dd hh:mm:ss)
 * @param {string|date} val 
 * @param {string} orig 
 * @param {string} dest 
 */
 export function formatFecha(val,orig,dest){
	// console.log('formatFecha: '+val+' '+orig+' '+dest)
	if (!val)//si val es nulo devolvemos la cadena vacia
		return('');
	var patt,format,hoy,f,anio,mes,dia,hora,min,seg;
	if (orig==='ing'){//formato en inglés 
		patt = new RegExp(/^([0-9]{4})-([0-9]{2})-([0-9]{2})$/);
		anio=1;
		mes=2;
		dia=3;
	}
	else if (orig==='ingL'){//formato en inglés con hora .Asumo que es correcto
		patt = new RegExp(/^([0-9]{4})[/-]([0-9]{1,2})[/-]?([0-9]{1,2})?[\s]?([0-9]{1,2})?[:]?([0-9]{1,2})?[:]?([0-9]{2})?$/);
		anio=1;
		mes=2;
		dia=3;
		hora=4;
		min=5;
		seg=6;
	}
	else if (orig==='ISO8601'){//formato en inglés sin guiones
		patt = new RegExp(/^([0-9]{4})([0-9]{2})([0-9]{2})$/);
		anio=1;
		mes=2;
		dia=3;
	}
	else if (orig==='esp'){//formato en español (más laxo) puede no ser correcto
		patt = new RegExp(/^([0-9]{1,2})[/-]([0-9]{1,2})[/-]?([0-9]{0,4})?$/);
		anio=3;
		mes=2;
		dia=1;
	}
	else if (orig==='espL'){//formato en español con hora (más laxo) puede no ser correcto
		patt = new RegExp(/^([0-9]{1,2})[/-]([0-9]{1,2})[/-]?([0-9]{0,4})?[\s]?([0-9]{1,2})?[:]?([0-9]{2})?[:]?([0-9]{2})?$/);
		anio=3;
		mes=2;
		dia=1;
		hora=4;
		min=5;
		seg=6;
	}
	else if (orig==='object'){//es un objeto
		format=['',val.day,val.month,val.year]
		anio=3;
		mes=2;
		dia=1;
	}
	else if (orig==='jav')//es un Object tipo Date
		f=val;
	else{//no está definido este origen
		// console.log('Error:formatFecha no tiene definido el origen: '+orig); 
		return (null);
	}
	if (orig!=='jav'){// no es un Date: ejecutamos el patron
		if (orig!=='object')
			if(!(format=val.match(patt)))//el patrón no fufa
				return (null);
		hoy=new Date();
		anio=format[anio];
		mes=format[mes];
		dia=format[dia];
		hora=format[hora];
		min=format[min];
		seg=format[seg];	
		if (typeof(hora)==='undefined') hora='00'
		if (typeof(min)==='undefined') min='00'
		if (typeof(seg)==='undefined') seg='00'
		if (typeof(anio)==='undefined')
			//no se ha definido el año, y ponemos el año más cercano a hoy
			if (15638400000<Math.abs(hoy-new Date(`${hoy.getFullYear()}-${mes}-${dia}`)))
				anio=hoy.getFullYear()-1;
			else
				anio=hoy.getFullYear();
		// si el año no está completo le pongo el resto dependiendo del año actual
		if ((anio+'').length<4)
			anio=String(hoy.getFullYear()).substr(0,4-anio.length)+anio;	
		if ((hora+'').length<2)
			hora='0'+hora;
		if ((mes+'').length<2)
			mes='0'+mes;
		if ((dia+'').length<2)
			dia='0'+dia;

		f=new Date(`${anio}-${mes}-${dia}T${hora}:${min}:${seg}`);
		if (isNaN(f))// fecha invalida
			return (null);
		else if (f.getDate()!==dia*1) //se ha puesto una fecha tipo 30 de feb
			return (null);
	}
	//empezamos con las salidas. 
	if (dest==='jav')//salida tipo Object(Date)
		return (f);
	else{
		// formato dd o mm
		mes=("0" + String(f.getMonth()+1)).slice(-2);
		dia=("0" + String(f.getDate())).slice(-2);
		anio=f.getFullYear();
		hora=f.getHours();
		if (hora<10) hora='0'+hora
		min=f.getMinutes();
		if (min<10) min='0'+min
		seg=f.getSeconds();
		if (seg<10) seg='0'+seg
		if (dest==='esp')//salida en español
			return(`${dia}/${mes}/${anio}`);
		else if (dest==='espL')//salida en español mas hora
			return(`${dia}/${mes}/${anio} ${hora}:${min}:${seg}`);
		else if (dest==='object')
			return({day:parseInt(dia),month:parseInt(mes),year:parseInt(anio)});
		else if (dest==='ISO8601')
			return(`${anio}${mes}${dia}`);
		else if (dest==='ing')//salida en inglés 
			return(`${anio}-${mes}-${dia}`);
		else if (dest==='ingL')//salida en inglés más hora (la hora habría que programarla)
			return(`${anio}-${mes}-${dia} ${hora}:${min}:${seg}`);
		else{
			// console.log('Error:formatFecha no tiene definido el destino: '+dest); 
			return (null);
		}
	}
}