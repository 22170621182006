import React from 'react';
import "../assets/css/organigrama-listing.css";

import { ReactComponent as PhoneIcon } from "../assets/img/icon-phone.svg";
import { ReactComponent as MailIcon } from "../assets/img/icon-mail.svg";

const OrganigramaListing = ({ data }) => {
    return (
        <div className='organigrama-listing__wrapper'>
            <ul>
                {data.organigrama.map(({ nombre, cargo, telefono, email, imagen }, index) => (
                    <li key={index}>
                        <div className='organigrama-listing__img'>
                            <img src={imagen} alt={nombre} />
                        </div>
                        <div className="organigrama-listing__content">
                            <h2>{nombre}</h2>
                            <span>{cargo}</span>
                            <div className="divider--horizontal"></div>
                            <span><PhoneIcon />{telefono}</span>
                            <span><MailIcon /><a href={`mailto:${email}`}>{email}</a></span>
                        </div>
                    </li>
                ))}
            </ul>
        </div>
    );
}

export default OrganigramaListing;
