import React, { useState } from "react";
import "../assets/css/layout-presets.css";

const LayoutPresets = ({ layoutContent }) => {
    const { TITULAR, FECHA, LAYOUT, IMAGEN, CONTENIDO } = layoutContent;

    // Estado para controlar el acordeón del layout
    const [acordeonAbierto, setAcordeonAbierto] = useState(false);

    // Función para alternar el estado del acordeón
    const toggleAcordeon = () => {
        setAcordeonAbierto((prevEstado) => !prevEstado);
    };

    // Función para renderizar el contenido en función del tipo
    const renderContenido = (item, index) => {
        if (item.TIPO === "texto") {
            return (
                <div
                    key={index}
                    dangerouslySetInnerHTML={{ __html: item.VALOR }}
                />
            );
        } else if (item.TIPO === "img") {
            return (
                <img
                    key={index}
                    src={item.VALOR}
                    alt={item.ALT}
                    width={item.ANCHO}
                    height={item.ALTO}
                />
            );
        }
        return null;
    };

    // Renderizado del layout
    const renderLayout = () => {
        switch (LAYOUT) {
            case "columnas":
                return (
                    <div className="layout-preset">
                        <div className="header__wrapper">
                            <div className="header__content">
                                <h3>{FECHA}</h3>
                            </div>
                        </div>
                        <div className="content__wrapper--column">
                            {CONTENIDO.map((item, index) => renderContenido(item, index))}
                        </div>
                    </div>
                );
            case "bloques":
                return (
                    <div className="layout-preset">
                        <div className="content__wrapper--row">
                            {CONTENIDO.map((item, index) => renderContenido(item, index))}
                        </div>
                    </div>
                );
            default:
                return <p>Selecciona un layout válido ("bloques" o "columnas")</p>;
        }
    };

    return (
        <div className="layout-acordeon-container">
            <div
                className={`titulo acordeon-trigger ${
                    acordeonAbierto ? "abierto" : "cerrado"
                }`}
                onClick={toggleAcordeon}
            >
                <div className="img-container">
                    {IMAGEN && <img src={IMAGEN} alt="Icono" />}
                </div>
                <div>
                    <h2>{TITULAR}</h2>
                    <h3>{FECHA}</h3>
                </div>
            </div>            

            {acordeonAbierto && <div className="acordeon-content">{renderLayout()}</div>}
        </div>
    );
};

export default LayoutPresets;
