import React, { useContext, Fragment } from 'react'
import { Modal } from 'react-bootstrap'
import ConexContext from '../context/ConexContext'

//IMG
import close_icon from '../assets/img/close.svg'


export const ModalCursosdeverano = ({ show, onHide, info, img }) => {
	const { loc } = useContext(ConexContext)//contexto
	const cursos = ["ALCAUDETE", "BAEZA", "BEDMAR", "CAZORLA", "JAEN", "CAROLINA", "SANTIAGO", "TORRECAMPO", "TORRES"]

	return (show ?
		<Modal
			show={show}
			onHide={onHide}
			animation={false}
			centered
			size='xl'
			className='modal'
		>
			<Modal.Body>
				<div className="col-12 contenidomodal">
					<button type="button" onClick={() => { onHide() }} className="modal-close" ><img src={close_icon} alt='cerrar' title='(ESC)' /></button>
					<img src={img} alt={loc("CURSO-VERANO-" + cursos[info])["TITULO"]} className="imgmodal" />
					<div className="textomodal">
						<div className='contenido-cursos'>
							<h1>{loc("CURSO-VERANO-" + cursos[info])["TITULO"]}</h1>
							<h2>{loc("CURSO-VERANO-" + cursos[info])["FECHA"]}</h2>
							<div className='cursos-container'>
								{
									loc("CURSO-VERANO-" + cursos[info])["CURSOS"].map((elem, i) => (
										<Fragment key={i}>
											<div className='curso-item'>
												<span>{elem["FECHA"]}</span>
												<h3>{elem["TITULO"]}</h3>
												<div className='ponentes-wrapper' key={elem["TITULO"] + i}>
													<h4>Dirección académica</h4>
													<div className='ponentes-container'>
														{elem["PONENTE"].map((elem2, j) => (
															<div className='ponente' key={elem2 + i + j}>{elem2}</div>
														))
														}
													</div>
												</div>
												<a className="botoninfo" href={elem["LINK"]} target='blank' rel="nofollow">Me interesa</a>
											</div>
										</Fragment>
									)
									)
								}
							</div>

						</div>

						<ul className='logos-patrocinadores'>
							{loc("CURSO-VERANO-" + cursos[info])["COLABORACIONES"].map((elem, i) => (
								<li key={i}>
									<img src={elem["URL"]} alt={elem["TITULO"]} title={elem["TITULO"]} style={{ "width": elem["WIDTH"] + "px" }} />
								</li>
							))}
						</ul>

					</div>

				</div>
			</Modal.Body>
		</Modal>
		: ''
	)
}
