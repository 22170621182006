import React, {useCallback, useReducer} from "react";

import ConexContext from "./ConexContext";
import ConexReducer from "./ConexReducer";

//COOKIES
import Cookies from 'js-cookie'

const ConexState = (props) => {
	const initialState = {
		locale: null,
		cookie: null
	};
	const [state, dispatch] = useReducer(ConexReducer, {initialState});
	const config = require('../config/config.json');
  
	//diccionario de idiomas
	const locES = require('../config/locale/es.json');
	const locEN = require('../config/locale/en.json');

	/**
	* lanza una peticion al microservicio
	* @param {string} url 
	*/
	const peticion = useCallback(async (url, {method='GET', headers={}, json=null}={})=>{
		if (json & method==='GET')
			return({
				mensaje:"Imposible mandar JSON con el método GET",
				estado:3
			})
		let data
		// console.log('getToken',getToken.current );
		try{
			let conexBack=config.conexBack
			let host=conexBack.host;
			let param={
				method: method,
				headers: headers
			}
			// param.headers.authorization=getToken.current

			if (json){
				param.headers['Content-Type']='application/json'
				param['body']=JSON.stringify(json)
			}
			if (conexBack?.port??null) //si el puerto es 0 no se usa
				host+=':'+conexBack.port
			setCargando(true)
			data= await fetch(`${host}/${conexBack.app}/${conexBack.version}${url}`,param);
			setCargando(false)
		}
		catch {
			return({
				mensaje:"El microservicio no responde",
				estado:1
			})
		}
		if (data.status!==200)
			return({
				mensaje:`Problema de conexión. ${data.status}. ${data.statusText}. ${data.type}`,
				estado:2
			})
		data= await data.json();
		data=Object.assign({peticion: url}, data);
		if (config.DebugMode)
			console.log(data)

		return (data)
	},[config])

	/**
	* devuelve la traducción de la palabra
	* @param {string} palabra
	*/
	const loc = (palabra)=>{
		switch (state.locale) {
			case 'es':
				return (palabra in locES?locES[palabra]:palabra);
			case 'en':
				return (palabra in locEN?locEN[palabra]:palabra);
			default:
				return (palabra);
		}
	}


	const setCargando=  async (val) => {
		dispatch({ type: 'SET_CARGANDO', payload: val});
	};

  const setLocale=useCallback((token) =>{
		dispatch({ type: 'SET_LOCALE', payload: token});
	},[]);

	/**
	 * Acepta o rechaza las cookies. Guarda la opción en el estado y en una cookie
	 */
	const setCookie=useCallback((accepted) =>{
		dispatch({ type: 'SET_COOKIE', payload: accepted});
		Cookies.set("cookieConsent", accepted);
	},[]);


	return (
		<ConexContext.Provider
			value={{
				locale: state.locale,
				cookie: state.cookie,
				setLocale,
				setCookie,
				loc,
				peticion
			}}
		>
			{props.children}
		</ConexContext.Provider>
	);
};

export default ConexState;