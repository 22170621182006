import { useEffect, useRef } from "react";
import React from "react";
import logo from "../assets/img/logo.png";
import "../assets/css/cartel-builder.css";

const CartelBuilder = React.memo(({ curso, tipos }) => {
	const wrapperRef = useRef(null);
	const titleRef = useRef(null);
    const subtitleRef = useRef(null);
	const ectsRef = useRef(null);

	useEffect(() => {
		function adjustFontSize() {
			if (wrapperRef.current && titleRef.current && ectsRef.current) {
				const wrapperWidth = wrapperRef.current.offsetWidth;

				// Ajusta los tamaños de fuente en función del ancho del contenedor padre
				titleRef.current.style.fontSize = `${wrapperWidth * 0.05}px`;
                subtitleRef.current.style.fontSize = `${wrapperWidth * 0.04}px`;
				ectsRef.current.style.fontSize = `${wrapperWidth * 0.03}px`;
			}
		}

		// Ajusta el tamaño del texto al montar y al cambiar el tamaño de la ventana
		adjustFontSize();
		window.addEventListener("resize", adjustFontSize);

		return () => {
			window.removeEventListener("resize", adjustFontSize);
		};
	}, []);

	if (!curso) return null;

	return (
		<div 
		className={`cartel-builder__wrapper 
			${curso.estado === "Disponible en breve" ? "closed" : 
			 (curso.estado === "Finalizado" || curso.estado === "Matrícula finalizada") ? "finished" : ""}`}
		ref={wrapperRef}>
			<div className="cartel-builder__logo">
				<img src={logo} alt="Universidad de Jaén" />
			</div>
			<div className="cartel-builder__availability">{curso.estado}</div>
			<div className="cartel-builder__content">
				<div className="cartel-builder__top">
					<div className="cartel-builder__top-left"></div>
					<div className="cartel-builder__top-right">
						<div className="cartel-builder__title" ref={titleRef}>
							{curso.titulo ? curso.titulo : "Sin información"}
						</div>

						<div className="cartel-builder__type" ref={subtitleRef}>
							{(Array.isArray(tipos) && tipos.find(tipo => tipo.nombre.includes(curso.tipo))?.label) ?? 'Sin información'}
						</div>
						<div className="cartel-builder__ects" ref={ectsRef}>
							{curso.ects ? curso.ects : "Sin información"} ECTS
						</div>
					</div>
				</div>
				<div className="cartel-builder__bottom"></div>
			</div>
			<div
				className="cartel-builder__backdrop"
				style={{ backgroundColor: curso.color }}></div>
			<div className="cartel-builder__background">
				{curso.img && (
					<img src={"../assets/img/" + curso.img} alt={curso.titulo} />
				)}
			</div>
		</div>
	);
});

export default CartelBuilder;
