import React, { useContext, useEffect } from "react";
import { Link, useParams } from "react-router-dom";

import img9 from "../assets/img/9.png";

//CONTEXT
import ConexContext from "../context/ConexContext";

export const Tecnologias = () => {
	const { lang } = useParams(); //parametros de la url
	const { loc } = useContext(ConexContext); //contexto

	useEffect(() => {
		document.title = "Tecnologías Educativas";
	}, []);

	return (
		<main className="col-12">
			<div className="empresas tecnologias">
				{/* <div className="col-12 volver">
					<div className='col-12 contvolver'>
						<Link to={{pathname:`/${lang}/inicio`}} alt="volver al inicio">
							{loc("Volver")}
						</Link>
					</div>
					<a href="#main-content" className="oculto">
						Saltar al contenido principal
					</a>
				</div> */}
				<div className="col-12 contslider">
					<div className="col-12 info">
						<div className="col-6">
							<img
								src={img9}
								alt="Tecnologías Educativas"
								className="sliderimg"
							/>
						</div>
						<div className="col-6">
							<h1>{loc("TECNOLOGIAS-TITULO")}</h1>
							<p>{loc("TECNOLOGIAS-SUBTITULO")}</p>
						</div>
					</div>
				</div>
			</div>

			<nav aria-label="breadcrumb" className="migapan rojo">
				<ol className="breadcrumb">
					<li className="breadcrumb-item">
						<Link to={{ pathname: `/${lang}/inicio` }}>
							{loc("NAVBAR-INICIO")}
						</Link>
					</li>
					<li className="breadcrumb-item active" aria-current="page">
						{loc("NAVBAR-TECNOLOGIAS")}
					</li>
				</ol>
			</nav>
			<main className="col-12 intsection">
				<div className="flex-column">
					<h2>{loc("TECNOLOGIAS-INFO1-TITULO")}</h2>
					<p dangerouslySetInnerHTML={{ __html: loc("TECNOLOGIAS-INFO1-SUBTITULO")}}></p>
				</div>
			</main>
		</main>
	);
};
