import React, { useContext, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
// import ReactPlayer from 'react-player';
//import img9 from "../assets/img/9.png";
import img9 from "../assets/img/genia-logo-blanco-01.png";
import LayoutPresets from "./LayoutPresets";

//CONTEXT
import ConexContext from "../context/ConexContext";

export const Genia = () => {
	const genia = require("../config/locale/es.json").GENIA;
	const { lang } = useParams(); //parametros de la url
	const { loc } = useContext(ConexContext); //contexto

	console.log("genia", genia);

	useEffect(() => {
		document.title = "Proyecto GenIA UJA";
	}, []);

	return (
		<>
			<div className="empresas tecnologias">
				<div className="col-12 contslider">
					<div className="col-12 info">
						<div className="col-6">
							<img src={img9} alt="GenIA" className="sliderimg genia" />
						</div>
						<div className="col-6">
							<h1>{loc("GENIA-TITULO")}</h1>
							<p>{loc("GENIA-SUBTITULO")}</p>
						</div>
					</div>
				</div>
			</div>

			<nav aria-label="breadcrumb" className="migapan rojo">
				<ol className="breadcrumb">
					<li className="breadcrumb-item">
						<Link to={{ pathname: `/${lang}/inicio` }}>
							{loc("NAVBAR-INICIO")}
						</Link>
					</li>
					<li className="breadcrumb-item active" aria-current="page">
						{loc("NAVBAR-GENIA")}
					</li>
				</ol>
			</nav>

			<main>
				<div className="col-12 intsection puzzle">
					{genia.map((item, index) => (
						<div className="col-12 layout-preset__wrapper" key={index}>
							<LayoutPresets layoutContent={item} />
						</div>
					))}
				</div>
			</main>
		</>
	);
};
