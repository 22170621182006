import { useRef } from "react";
import "@hassanmojab/react-modern-calendar-datepicker/lib/DatePicker.css";
import { Calendar } from "@hassanmojab/react-modern-calendar-datepicker";
import * as calendarCustom from "../../assets/js/locateCalendar";
import clickOut from "./clickOut";

const Calendario = ({
  date,
  setDate,
  calendView,
  setCalendView,
  minimumDate,
  Cliente,
  setCliente
  // setactualizar,
}) => {
  const divCalendar = useRef();

  // const hoy = new Date();
  const updateDate = (date) => {    
    const year = date.year.toString();
    const month = date.month.toString().padStart(2, 0);
    const day = date.day.toString().padStart(2, 0);
    date = new Date(`${year}/${month}/${day}`);
    setDate(date);
    if(Cliente){
      let value = {...Cliente}
      value.fecha.Nombre=date
      value.fecha.Codigo=formatDateToString(date)
      setCliente(value)
    }
    // setactualizar(true);
    setCalendView("hide");
  };
  // devuelve la fecha en formato object (para el calendar)
  function fechaObjet(date) {
    return {
      day: date.getDate(),
      month: date.getMonth() + 1,
      year: date.getFullYear(),
    };
  }

    // devuelve la fecha en ddmmaaaa de un valor date
    function formatDateToString(date) {
      const day = String(date.getDate()).padStart(2, '0');
      const month = String(date.getMonth() + 1).padStart(2, '0'); // Los meses van de 0 a 11
      const year = String(date.getFullYear());
    
      return `${day}${month}${year}`;
    }
  //cierra el calendario
  clickOut(divCalendar, () => {
    // setactualizar(true);
    setCalendView("hide");
  });

  return (
    <div
      style={{ left: 0 }}
      className={
        calendView === "hide" ? "calendar-hide" : "calendar-show"
      }
    >
      {calendView === "reload" ? (
        ""
      ) : (
        <Calendar
          value={fechaObjet(date)}
          onChange={updateDate}
          slideAnimationDuration={"1s"}
          locale={calendarCustom.locale_es}
          // maximumDate={fechaObjet(hoy)}
          minimumDate={minimumDate}
          colorPrimary="#00aedc"
          colorPrimaryLight="rgba(0,174,220,0.4)"
          shouldHighlightWeekends
        />
      )}
    </div>
  );
};

export default Calendario;
