import React, { useContext, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";

import img2 from "../assets/img/2.png";
import CursoListing from "./CursoListing";

//CONTEXT
import ConexContext from "../context/ConexContext";

export const Estudiantes = () => {
	const { lang } = useParams(); //parametros de la url
	const { loc, peticion } = useContext(ConexContext); //contexto
	const [categoriesMap, setcategoriesMap] = useState(null);
	const [tipos, settipos] = useState(null);

	useEffect(() => {
		document.title = "Estudiantes";
	}, []);

	useEffect(() => {
		//pide las categorias (solo al principio)
		async function categorias_peticion() {
			const pet = await peticion('/categorias', { method: 'POST' })
			if (pet.estado) //algún error
				return;
				setcategoriesMap(pet.objeto);
		}
		//pide los tipos (solo al principio)
		async function tipos() {
			const pet = await peticion('/tipos', { method: 'POST' })
			if (pet.estado) //algún error
				return;
				settipos(pet.objeto);
		}

		if (categoriesMap) return
		categorias_peticion()
		tipos()
	}, [categoriesMap, peticion]);

	return (
		<>
			<div className="estudiantes">
				<div className="col-12 contslider">
					<div className="col-12 info">
						<div className="col-6">
							<h1>{loc("ESTUDIANTES-TITULO")}</h1>
							<p>{loc("ESTUDIANTES-SUBTITULO")}</p>
						</div>
						<div className="col-6">
							<img src={img2} alt="Estudiantes" className="sliderimg" />
						</div>
					</div>
				</div>
			</div>

			<nav aria-label="breadcrumb" className="migapan verdeclaro">
				<ol className="breadcrumb">
					<li className="breadcrumb-item">
						<Link to={{ pathname: `/${lang}/inicio` }}>
							{loc("NAVBAR-INICIO")}
						</Link>
					</li>
					<li className="breadcrumb-item active" aria-current="page">
						{loc("NAVBAR-ESTUDIANTES")}
					</li>
				</ol>
			</nav>

			{tipos && categoriesMap &&
				<CursoListing data={{"tipos": tipos, "categorias": categoriesMap, "cursos-pdi": null}} json={false} nombre_CAT={"Área de conocimiento"}/>
			}
		</>
	);
};
