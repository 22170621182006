import CartelBuilder from "./CartelBuilder";
import React from "react";
// import cursosData from "../cursos-pdi.json";

//helpers
import { formatNumero } from "../helpers/formatNumero";

const ModalCursoHover = React.memo(({ curso, onClose, cursosData, json }) => {

	// Recibe un string y devuelve el valor de label del valor value que coincida con el string
	const getLabel = (value) => {
		const options=[
			{
				"label": "Presencial",
				"value": "P"
			},
			{
				"label": "Semipresencial",
				"value": "SP"
			},
			{
				"label": "Online",
				"value": "AD"
			},
			{
				"label": "Virtual",
				"value": "V"
			}
		]

		const option = options.find((option) => option.value === value);
		return option ? option.label : "";
	};

	if (!curso) return null;

	return (
		<div className="modal-cursos-hover__wrapper">
			<CartelBuilder curso={curso} json={json} tipos={cursosData["tipos"]}/>
			<div className="modal-cursos-hover__content">
				<div className="modal-cursos-hover__header">
					<div className="flex-horizontal">
						<div className="modal-cursos-hover__ects">{curso.ects ? (curso.ects):('Sin informacion')} ECTS</div>
						<div className="modal-cursos-hover__hours">{curso.ects ? (curso.ects*10):('Sin informacion')}h</div>
					</div>
					<div className="modal-cursos-hover__modalidad">{curso.modalidad ? (getLabel(curso.modalidad)):('Sin informacion')}</div>
				</div>
				<div className="modal-cursos__content--price">
					{curso.precio && formatNumero(curso.precio, "€", ".", 2, 2)}
				</div>
				<ul className="modal-cursos-hover__taxonomies">
					<li className="modal-cursos-hover__category">
						{
							cursosData["categorias"].find((categoria) =>
								curso.categorias.includes(categoria.nombre)
							)?.label
						}
					</li>
					<li>·</li>
					<li className="modal-cursos-hover__type">
						{
							cursosData["tipos"].find((tipo) => tipo.nombre.includes(curso.tipo))
								?.label
						}
					</li>
				</ul>
				<div className="modal-cursos-hover__ponentes">
				<>Impartido por: <span>{curso.ponente.trim() ? (curso.ponente):('Sin informacion')}</span></>
				</div>
				{curso.url && 
					<a
						className="modal-cursos-hover__cta"
						href={curso.url}
						target="blank"
						rel="nofollow"
					>
						Me interesa
					</a>
				}
			</div>
		</div>
	);
});

export default ModalCursoHover;
