import {
	BrowserRouter,
	Routes,
	Route,
	Navigate,
	useParams
} from "react-router-dom";

import Layout from "./components/Layout";

import { Inicio } from "./components/Inicio";
import { Estudiantes } from "./components/Estudiantes";
import { PDI } from "./components/PDI";
import { PTGAS } from "./components/PTGAS";
import { Empresas } from "./components/Empresas";
import { Microcredenciales } from "./components/Microcredenciales";
import { EdPuzzle } from "./components/EdPuzzle";
import { Genia } from "./components/Genia";
import { Innovacion } from "./components/Innovacion";
import { Cursosdeverano } from "./components/Cursosdeverano";
import { Tecnologias } from "./components/Tecnologias";
import { MapaWeb } from "./components/Mapaweb";
import { Accesibilidad } from "./components/Accesibilidad";
import { CookiesProp } from "./components/Cookies";
import { Error } from "./components/error";
import { Centro } from "./components/Centro";
import { Organigrama } from "./components/Organigrama";
import { Normativa } from "./components/Normativa";
import { Resoluciones } from "./components/Resoluciones";
import { NormativaInno } from "./components/NormativaInno";
import { ResolucionesInno } from "./components/ResolucionesInno";
import { Tramitacion } from "./components/Tramitacion";
import { Becas } from "./components/Becas";

const Seccion = () => {
    const { seccion } = useParams();

    switch (seccion) {
        case 'inicio':
            return <Inicio />;
        case 'estudiantes':
            return <Estudiantes />;
        case 'pdi':
            return <PDI />;
        case 'ptgas':
            return <PTGAS />;
        case 'empresas':
            return <Empresas />;
        case 'microcredenciales':
            return <Microcredenciales />;
        case 'edpuzzle':
            return <EdPuzzle />;
        case 'genia':
            return <Genia />;
        case 'innovacion':
            return <Innovacion />;
        case 'tecnologias':
            return <Tecnologias />;
        case 'mapa_web':
            return <MapaWeb />;
        case 'accesibilidad':
            return <Accesibilidad />;
        case 'cookies':
            return <CookiesProp />;
        case 'cursosdeverano':
            return <Cursosdeverano />;
        case 'centro':
            return <Centro />;
        case 'organigrama':
            return <Organigrama />;
        case 'normativa':
            return <Normativa />;
        case 'resoluciones':
            return <Resoluciones />;
        case 'normativainno':
            return <NormativaInno />;
        case 'resolucionesinno':
            return <ResolucionesInno />;
        case 'tramitacion':
            return <Tramitacion />;
        case 'becas':
            return <Becas />;

        default:
            return <Error />;
    }
};

function App() {
	return (
		<BrowserRouter>
			<Routes>
				<Route path="/" element={<Navigate to="/es/inicio" /> } exact />
				<Route path="/:lang" element={<Layout />}>
					<Route index element={<Inicio />} />
					<Route path="/:lang/:seccion" element={<Seccion />} />
				</Route>
			</Routes>
		</BrowserRouter>
	);
}

export default App;
