import React from 'react';
import ReactDOM from 'react-dom';

import App from './App';
import ConexState from './context/ConexState';

//CSS
import './assets/css/bootstrap.css';
import './assets/css/uja.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';

ReactDOM.render(
	<React.StrictMode>
		<ConexState>
			<App />
		</ConexState>
	</React.StrictMode>,
	document.getElementById('root')
);
