/**
 *Funcion que formatea numeros simb es útil para añadir % o €. Dec son los decimales, si no se indican 
 * @param {number} val valor a formatear (también acepta string)
 * @param {string} simb símbolo para poner al final
 * @param {string} mil separador de miles. '' no pondría nada
 * @param {number} dec número de decimales a redondear. 
 * @param {number} decmax si quieres permitir más decimales puntualmente.
 * @returns {string}
 */
 export function formatNumero(val,simb,mil,dec=0,decmax){
	//Asumo que se mandan o bien números o cadenas con el simb 
	// eso sí, nunca con separador de miles
	if (val===null)
	 return ('')
	let v=val.toString();
	v=v.replace(simb,''); 
	v=v.replace(',','.');
	let vArr=v.split('.'); //separo la parte entera de la decimal
	if(vArr.length>2){// hay separadores de miles
		v='';
		for (var i=0;i<vArr.length-1;i++)
			v+=vArr[i];
		v+='.'+vArr[i];
	}
	//redondeo	
	if (decmax===undefined)
		decmax=dec;	
	if (dec!==undefined)
		v=(Math.round(v*Math.pow(10,decmax))/Math.pow(10,decmax)).toString()
	v=v.split('.'); //separo la parte entera de la decimal
	let valEnt=v[0];
	if (isNaN(valEnt))
		return(NaN)
	let valDec=(v[1]===undefined?'':v[1]);
	//ajusto los decimales
	for (i=valDec.length;i<dec;i++)
		valDec+='0';
	if (valDec!=='') valDec=','+valDec
	// separadores de miles
	let Miles='';
	for (i=0;i<valEnt.length;i++){
		if ((i)&&(i%3===0)&&(valEnt.charAt(valEnt.length-i-1)!=='-')){
			Miles=mil+Miles;
		}
		Miles=valEnt.charAt(valEnt.length-i-1)+Miles;
	}
	valEnt=Miles; 
	val=valEnt+valDec+simb;
	return(val);
}