import React, { useState, useEffect, useRef } from "react";
import { ReactComponent as IconArrow } from "../assets/img/arrowdown.svg";

const MultiSelectDropdown = ({ options, label, selectedOptions, setSelectedOptions }) => {
	const dropdownRef = useRef(null); // Crear el ref

	useEffect(() => {
		const handleClickOutside = (event) => {
			if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
				setIsOpen(false);
			}
		};

		document.addEventListener("mousedown", handleClickOutside);

		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, []);

	// const [selectedOptions, setSelectedOptions] = useState([]);
	const [isOpen, setIsOpen] = useState(false);

	const toggleDropdown = () => setIsOpen(!isOpen);

	const handleOptionToggle = (option) => {
		const isSelected = selectedOptions.some(selected => selected.value === option.value);

		if (isSelected) {
			// Elimina la opción seleccionada
			setSelectedOptions(selectedOptions.filter(item => item.value !== option.value));
		} else {
			// Añade la opción seleccionada
			setSelectedOptions([...selectedOptions, option]);
		}
	};

	return (
		<div
			className={`ms-dropdown ${isOpen ? "open" : ""}`}
			ref={dropdownRef} // Asignar el ref aquí
		>
			<label>{label}</label>
			<div className="ms-dropdown__header" onClick={toggleDropdown}>
				<span>
					{selectedOptions.length
						? selectedOptions.map(option => option.label).join(", ")
						: "Seleccionar..."}
				</span>
				<IconArrow />
			</div>
			{isOpen && (
				<div className="ms-dropdown__list">
					{options.map((option) => (
						<label key={option.value} className="ms-dropdown__item">
							<input
								type="checkbox"
								checked={selectedOptions.some(selected => selected.value === option.value)}
								onChange={() => handleOptionToggle(option)}
							/>
							{option.label}
						</label>
					))}
				</div>
			)}
		</div>
	);
};

export default MultiSelectDropdown;