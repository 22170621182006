import React, { useContext,useEffect} from 'react'
import { Link, useParams } from 'react-router-dom';
// import ReactPlayer from 'react-player';
import img9 from '../assets/img/9.png'

import ResolucionesListing from "./ResolucionesListing";
import normativasData from "../normativas-resoluciones.json";

//CONTEXT
import ConexContext from '../context/ConexContext'

export const ResolucionesInno = () => {
	const { lang } = useParams();//parametros de la url
	const { loc }=useContext(ConexContext)//contexto

	useEffect(() => {
		document.title = "Resoluciones Innovación Docente";
	  }, []);

	return (
		<>
			<div className="empresas tecnologias">
				<div className='col-12 volver'>
					{/* <div className='col-12 contvolver'>
						<Link to={{pathname:`/${lang}/inicio`}} alt="volver al inicio">
							{loc("Volver")}
						</Link>
					</div> */}
					<a href="#main-content" className="oculto">
					Saltar al contenido principal
				</a>
						
				</div>
				<div className="col-12 contslider">
					<div className="col-12 info">
						<div className="col-6">
							<img src={img9} alt="Resoluciones Innovación Docente" className="sliderimg" />
						</div>
						<div className="col-6">
							<h1>{loc("RESOLUCIONESINNO-TITULO")}</h1>
							<p>{loc("RESOLUCIONESINNO-SUBTITULO")}</p>
						</div>
					</div>
				</div>
			</div>

			<nav aria-label="breadcrumb" className="migapan rojo">
				<ol className="breadcrumb">
					<li className="breadcrumb-item">
						<Link to={{pathname:`/${lang}/inicio`}}>
							{loc("NAVBAR-INICIO")}
						</Link>
					</li>
					<li className="breadcrumb-item active" aria-current="page">{loc("NAVBAR-RESOLUCIONESINNO")}</li>
				</ol>
			</nav>

			<main>

			<div className="col-12 intsection puzzle">
					<div className="col-12">
						<div className="col-12 dflex">
							<div className="col-6 ">
								<h2 className='titulo'>{loc("RESOLUCIONESINNO-TITULO2")}</h2>
								<p>{loc("RESOLUCIONESINNO-DESC")}</p>								
							</div>								
						</div>
					</div>
					<ResolucionesListing data={normativasData} tipo={"resolucionesInno"} />					
			</div>

			</main>
		</>
	)
}
