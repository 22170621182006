import React, { useContext, useEffect, useRef, useState } from 'react'

//IMG
import img1 from '../assets/img/1.png'
import img2 from '../assets/img/2.png'
import img3 from '../assets/img/3.png'
import img4 from '../assets/img/4.png'
import img5 from '../assets/img/5.png'
import img6 from '../assets/img/6.png'
import img7 from '../assets/img/7.png'
import img8 from '../assets/img/8.png'
//CONTEXT
import ConexContext from '../context/ConexContext'

export const Inicio = () => {

	useEffect(() => {
		document.title = "Centro de Formación e Innovación Docente - Universidad de Jaén / Center for Training and Teaching Innovation - University of Jaén";
	}, []);

	useEffect(() => {
		window.document.documentElement.lang = "es";
	}, []);

	const { loc } = useContext(ConexContext) //contexto

	const refArr = useRef([]); //Array de referencias a los elementos que se quieren animar

	const [scrollY, setScrollY] = useState(0); //Almacena el valor del scroll y siempre es incrementado

	/**
	 * Actualiza el estado scrollY con el valor del scroll
	 */
	const handleScroll = () => {
		setScrollY((prev) => window.scrollY > prev ? window.scrollY : prev);
	};

	/**
	 * Comprueba si el elemento es visible en la pantalla
	 * @param {integer} idx indice en el array de referencias del elemento a comprobar
	 * @returns {string} "visible" o "" para activar la animacion
	 */
	const isVisible = (idx) => {
		return (refArr?.current[idx]?.offsetTop - 1000 < scrollY) ? "visible" : ""
	}

	/**
	 * Añade el listener del scroll al montar el componente
	 */
	useEffect(() => {
		window.addEventListener("scroll", handleScroll);
		// Eliminar listener cuando el componente se desmonta
		return () => {
			window.removeEventListener("scroll", handleScroll);
		};
	}, []);

	return (
		<>
			<div className="inicio">
				<div className="col-12 contslider">
					<div className="col-12 info">
						<div className="col-6">
							<h1>{loc("INICIO-TITULO")}</h1>
							<p>{loc("INICIO-SUBTITULO")}</p>
						</div>
						<div className="col-6">
							<img src={img1} alt="Centro de Formación de Innovación Docente" className="sliderimg" />
						</div>		
					</div>
				</div>
			</div>

			<div className="col-12 contnovedades">
					<div className="novedad f1">
						<div>
							<h2>{loc("INICIO-NOVEDAD1")}</h2>
							<p>{loc("INICIO-NOVEDAD1SUB")}</p>
						</div>
						<button 
					
							onClick={() => {
								window.location.href = `/${window.location.pathname.split('/')[1]}/edpuzzle`;
							}}
						>
							<span>{loc("MAS-INFORMACION")}</span>
						</button>
					</div>
					<div className="novedad f2">
						<div>
							<h2>{loc("INICIO-NOVEDAD2")}</h2>
							<p>{loc("INICIO-NOVEDAD2SUB")}</p>
						</div>
						<button
						
							onClick={() => {
								window.location.href = `/${window.location.pathname.split('/')[1]}/pdi`;
							}}
						>
							<span>{loc("MAS-INFORMACION")}</span>
						</button>
					</div>
					<div className="novedad f3">
						<div>
							<h2>{loc("INICIO-NOVEDAD3")}</h2>
							<p>{loc("INICIO-NOVEDAD3SUB")}</p>
						</div>
						<button
						
							onClick={() => {								
								window.location.href = `/${window.location.pathname.split('/')[1]}/cursosdeverano`;
							}}
						>
							<span>{loc("MAS-INFORMACION")}</span>
						</button>
					</div>
			</div>

			<div className="col-12 item anima">
				<div className="col-12" ref={el => refArr.current[0] = el}>
					<div className={"col-6 " + isVisible(0)}>
						<img src={img2} alt="Estudiantes" className="sliderimg" />
					</div>
					<div className={"col-6 " + isVisible(0)}>
						<h2>{loc("NAVBAR-ESTUDIANTES")}</h2>
						<p>{loc("INICIO-P1")}</p>
						<button
							
							onClick={() => {
								window.location.href = `/${window.location.pathname.split('/')[1]}/estudiantes`;
							}}
						>
							<span>{loc("MAS-INFORMACION")}</span>
						</button>
					</div>
				</div>
			</div>

			<div className="col-12 item anima">
				<div className="col-12" ref={el => refArr.current[1] = el}>
					<div className={"col-6 " + isVisible(1)}>

						<h2>{loc("NAVBAR-PDI")}</h2>
						<p>{loc("INICIO-P2")}</p>

						<button
							href="#"
							onClick={() => {
								window.location.href = `/${window.location.pathname.split('/')[1]}/pdi`;
							}}
						>
							<span>{loc("MAS-INFORMACION")}</span>
						</button>

					</div>
					<div className={"col-6 " + isVisible(1)}>
						<img src={img3} alt="PDI" className="sliderimg" />
					</div>
				</div>
			</div>

			<div className="col-12 item anima">
				<div className="col-12 ensancha" ref={el => refArr.current[2] = el}>
					<div className={"col-6 " + isVisible(2)}>
						<img src={img4} alt="PTGAS" className="sliderimg fadeOut" />
					</div>
					<div className={"col-6 fixwidth " + isVisible(2)}>
						<h2>{loc("NAVBAR-PTGAS")}</h2>
						<p>{loc("INICIO-P3")}</p>

						<button
							href="#"
							onClick={() => {
								window.location.href = `/${window.location.pathname.split('/')[1]}/ptgas`;
							}}
						>
							<span>{loc("MAS-INFORMACION")}</span>
						</button>
					</div>
				</div>
			</div>

			<div className="col-12 item anima">
				<div className="col-12" ref={el => refArr.current[3] = el}>
					<div className={"col-6 " + isVisible(3)}>
						<h2>{loc("NAVBAR-EMPRESAS")}</h2>
						<p>{loc("INICIO-P4")}</p>

						<button
							href="#"
							onClick={() => {
								window.location.href = `/${window.location.pathname.split('/')[1]}/empresas`;
							}}
						>
							<span>{loc("MAS-INFORMACION")}</span>
						</button>
					</div>
					<div className={"col-6 " + isVisible(3)}>
						<img src={img6} alt="Empresas" className="sliderimg" />
					</div>
				</div>
			</div>

			<div className="col-12 item anima">
				<div className="col-12" ref={el => refArr.current[4] = el}>
					<div className={"col-6 " + isVisible(4)}>
						<img src={img5} alt="Microcredenciales" className="sliderimg" />
					</div>
					<div className={"col-6 " + isVisible(4)}>
						<h2>{loc("NAVBAR-MICROCREDENCIALES")}</h2>
						<p className='fixpdleft'>{loc("INICIO-P5")}</p>

						<button
							href="#"
							onClick={() => {
								window.location.href = `/${window.location.pathname.split('/')[1]}/microcredenciales`;
							}}
						>
							<span>{loc("MAS-INFORMACION")}</span>
						</button>
					</div>
				</div>
			</div>

			<div className="col-12 item anima">
				<div className="col-12" ref={el => refArr.current[5] = el}>
					<div className={"col-6 " + isVisible(5)}>
						<h2>{loc("NAVBAR-INNOVACION")}</h2>
						<p>{loc("INICIO-P6")}</p>

						<button
							href="#"
							onClick={() => {
								window.location.href = `/${window.location.pathname.split('/')[1]}/innovacion`;
							}}
						>
							<span>{loc("MAS-INFORMACION")}</span>
						</button>
					</div>
					<div className={"col-6 " + isVisible(5)}>
						<img src={img8} alt="Innovación docente" className="sliderimg" />
					</div>
				</div>
			</div>			

			<div className="col-12 item anima">
				<div className="col-12" ref={el => refArr.current[6] = el}>
					<div className={"col-6 " + isVisible(6)}>
						<img src={img7} alt="Tecnologías Educativas" className="sliderimg" />
					</div>
					<div className={"col-6 " + isVisible(6)}>
						<h2>{loc("NAVBAR-TECNOLOGIAS")}</h2>
						<p className='fixpdleft'>{loc("INICIO-P7")}</p>

						<button
							href="#"
							onClick={() => {
								window.location.href = `/${window.location.pathname.split('/')[1]}/tecnologias`;
							}}
						>
							<span>{loc("MAS-INFORMACION")}</span>
						</button>
					</div>
				</div>
			</div>
			
		</>
	)
}
