import React, { useContext, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";

import img5 from "../assets/img/5.png";

//CONTEXT
import ConexContext from "../context/ConexContext";
import CursoListingMicro from "./CursoListingMicro";

export const Microcredenciales = () => {
	const { lang } = useParams(); //parametros de la url
	const { loc, peticion } = useContext(ConexContext); //contexto
	const [categoriesMap, setcategoriesMap] = useState(null);

	useEffect(() => {
		document.title = "Microcredenciales";
	}, []);

	useEffect(() => {
		//pide las categorias (solo al principio)
		async function categorias_peticion() {
			const pet = await peticion('/categorias', { method: 'POST' })
			if (pet.estado) //algún error
				return;
				setcategoriesMap(pet.objeto);
		}
		//pide los tipos (solo al principio)

		if (categoriesMap) return
		categorias_peticion()
	}, [categoriesMap, peticion]);

	return (
		<>
			<div className="microcredenciales ">
				<div className="col-12 contslider">
					<div className="col-12 info">
						<div className="col-6">
							<h1>{loc("MICROCRED-TITULO")}</h1>
							<p>{loc("MICROCRED-SUBTITULO")}</p>
						</div>
						<div className="col-6">
							<img src={img5} alt="Microcredenciales" className="sliderimg" />
						</div>
					</div>
				</div>
			</div>

			<nav aria-label="breadcrumb " className="migapan marron">
				<ol className="breadcrumb">
					<li className="breadcrumb-item">
						<Link to={{ pathname: `/${lang}/inicio` }}>
							{loc("NAVBAR-INICIO")}
						</Link>
					</li>
					<li className="breadcrumb-item active" aria-current="page">
						{loc("NAVBAR-MICROCREDENCIALES")}
					</li>
				</ol>
			</nav>

			{categoriesMap &&
				<CursoListingMicro data={{"tipos": [{
					"label": "Microcredencial universitaria",
					"nombre": "MC",
					"descripcion": "Son formaciones breves (menos de 15 créditos ECTS), con una clara vocación de desarrollo profesional y de empleabilidad.",
					"fondo": "/assets/img/pool-cursos/img-47.jpg"
				}], "categorias": categoriesMap, "cursos-pdi": null}} json={false} nombre_CAT={"Área de conocimiento"}/>
			}
		</>
	);
};
