import React from "react";
import { Range, getTrackBackground } from "react-range";
//helpers
import { formatNumero } from "../helpers/formatNumero";

const RangeSlider = ({ min, max, step, label, values, setvalues, sufijo }) => {

	if (max < 4) {
		max = 4;
	}

	if (max % 4 !== 0) { 
		max = max + max % 4;
	}

	const array = [0,Math.round(max*0.25),Math.round(max*0.5),Math.round(max*0.75),max]
	const rangoiman = Math.ceil(max/20)

	const cambio = (values) => {
		for (let index = 0; index < array.length; index++) {
			if (Math.abs(values[1]-array[index])<rangoiman){
				values[1]=array[index]
			}
			if (Math.abs(values[0]-array[index])<rangoiman){
				values[0]=array[index]
			}
		}
		setvalues(values)
	};

	return (
		<>
			<label>{label}</label>
			<div className="range-slider">
				<Range
					values={values}
					step={step}
					min={min}
					max={max}
					onChange={cambio}
					renderTrack={({ props, children }) => (
						<div
							{...props}
							className="range-track"
							style={{
								...props.style,
								height: "4px",
								width: "100%",
								background: getTrackBackground({
									values,
									colors: ["rgb(220,220,220)", "#006d38", "rgb(220,220,220)"],
									min,
									max,
								}),
							}}
						>
							{children}
								<div className="range-interval-wrapper">
								<div className="range-interval">
								</div>
								<div className="range-interval">
								</div>
								<div className="range-interval">
								</div>
								<div className="range-interval">
								</div>
							</div>
						</div>
					)}
					renderThumb={({ props }) => (
						<div
							{...props}
							className="range-thumb"
							style={{
								...props.style,
								height: "20px",
								width: "20px",
								backgroundColor: "#006d38",
								borderRadius: "50%",
							}}
						/>
					)}
				/>
				<div className="range-values">
					<span>{formatNumero(values[0], sufijo || "", '.', 0, 0)}</span>
					{" - "}
					<span>{formatNumero(values[1], sufijo || "", '.', 0, 0)}</span>
				</div>
			</div>
		</>
	);
};

export default RangeSlider;
