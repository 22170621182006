import React, { useContext, useEffect } from "react";
import { Link, useParams } from "react-router-dom";

import img8 from "../assets/img/8.png";

//CONTEXT
import ConexContext from "../context/ConexContext";

export const Innovacion = () => {
	const { lang } = useParams(); //parametros de la url
	const { loc } = useContext(ConexContext); //contexto

	useEffect(() => {
		document.title = "Innovación Docente";
	}, []);

	return (
		<>
			<div className="empresas innovacion">
				{/* <div className="col-12 volver">
					<div className="col-12 contvolver">
						<Link to={{ pathname: `/${lang}/inicio` }} alt="volver al inicio">
							{loc("Volver")}
						</Link>
					</div>
					<a href="#main-content" className="oculto">
						Saltar al contenido principal
					</a>
				</div> */}
				<div className="col-12 contslider">
					<div className="col-12 info">
						<div className="col-6">
							<img
								src={img8}
								alt="Innovación tecnológica"
								className="sliderimg"
							/>
						</div>
						<div className="col-6">
							<h1>{loc("INNOVACION-TITULO")}</h1>
							<p>{loc("INNOVACION-SUBTITULO")}</p>
						</div>
					</div>
				</div>
			</div>

			<nav aria-label="breadcrumb" className="migapan azul">
				<ol className="breadcrumb">
					<li className="breadcrumb-item">
						<Link to={{ pathname: `/${lang}/inicio` }}>
							{loc("NAVBAR-INICIO")}
						</Link>
					</li>
					<li className="breadcrumb-item active" aria-current="page">
						{loc("NAVBAR-INNOVACION")}
					</li>
				</ol>
			</nav>

			<main>
				<div className="col-12 intsection">
					<div className="flex-column">
						<h2>{loc("INNOVACION-INFO1-TITULO")}</h2>

						<p>{loc("INNOVACION-INFO1-SUBTITULO")}</p>
					</div>
				</div>
			</main>
		</>
	);
};
