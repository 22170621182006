import React, { useContext, useEffect } from "react";
import { Link, useParams } from "react-router-dom";

import img6 from "../assets/img/6.png";

//CONTEXT
import ConexContext from "../context/ConexContext";

export const Empresas = () => {
	const { lang } = useParams(); //parametros de la url
	const { loc } = useContext(ConexContext); //contexto

	useEffect(() => {
		document.title = "Empresas";
	}, []);

	return (
		<>
			<div className="empresas">
				{/* <div className="col-12 volver">
					<div className="col-12 contvolver">
						<Link to={{ pathname: `/${lang}/inicio` }} alt="volver al inicio">
							{loc("Volver")}
						</Link>
					</div>
					<a href="#main-content" className="oculto">
						Saltar al contenido principal
					</a>
				</div> */}
				<div className="col-12 contslider">
					<div className="col-12 info">
						<div className="col-6">
							<h1>{loc("EMPRESAS-TITULO")}</h1>
							<p>{loc("EMPRESAS-SUBTITULO")}</p>
						</div>
						<div className="col-6">
							<img src={img6} alt="EMPRESAS" className="sliderimg" />
						</div>
					</div>
				</div>
			</div>

			<nav aria-label="breadcrumb" className="migapan gris">
				<ol className="breadcrumb">
					<li className="breadcrumb-item">
						<Link to={{ pathname: `/${lang}/inicio` }}>
							{loc("NAVBAR-INICIO")}
						</Link>
					</li>
					<li className="breadcrumb-item active" aria-current="page">
						{loc("NAVBAR-EMPRESAS")}
					</li>
				</ol>
			</nav>

			<main>
				<div className="col-12 intsection puzzle">
					<div className="col-12">
						<div className="flex-column">
							<h2 className='titulo'>{loc("EMPRESAS-TITULO2")}</h2>
							<p>{loc("EMPRESAS-DESC")}</p>								
						</div>
					</div>					
				</div>
			</main>
		</>
	);
};
