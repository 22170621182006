import React, { useContext, useEffect, useState, useRef } from "react";
import { Link, useParams } from "react-router-dom";

// import cartel1 from '../assets/img/generico1.png'
import cartel2 from "../assets/img/alcaudete.png";
import cartel3 from "../assets/img/Baeza.png";
import cartel4 from "../assets/img/bedmar.png";
import cartel5 from "../assets/img/cazorlaseguravillas.png";
import cartel6 from "../assets/img/jaen.png";
import cartel7 from "../assets/img/lacarolina.png";
import cartel8 from "../assets/img/santiagopontones.png";
import cartel9 from "../assets/img/torredelcampo.png";
import cartel10 from "../assets/img/torres.png";

//CONTEXT
import ConexContext from "../context/ConexContext";
import { ModalCursosdeverano } from "./ModalCursosdeverano";

export const Cursosdeverano = () => {
	const { lang } = useParams(); //parametros de la url
	const { loc } = useContext(ConexContext); //contexto

	const [show, setShow] = useState({ show: false, curso: 0 });
	const refArr = useRef([]); //Array de referencias a los elementos que se quieren animar
	/**
	 * Actualiza el estado scrollY con el valor del scroll
	 */

	useEffect(() => {
		document.title = "Curso de verano";
	}, []);

	return (
		<>
			<ModalCursosdeverano
				show={show.show}
				onHide={() => setShow({ show: false, curso: 0 })}
				info={show.curso}
				img={show.img}
			/>
			<div className="cursosdeverano">
				{/* <div className="col-12 volver">
					<div className='col-12 contvolver'>
						<Link to={{pathname:`/${lang}/inicio`}} alt="volver al inicio">
							{loc("Volver")}
						</Link>
					</div>
					<a href="#main-content" className="oculto">
						Saltar al contenido principal
					</a>
				</div> */}
				<div className="col-12 bannerslider contslider">
					<div className="col-12 info">
						<div className="col-3">
							<h1>{loc("CURSOS-VERANO-TITULO")}</h1>
						</div>
						<div className="col-9"></div>
					</div>
				</div>
			</div>

			<nav aria-label="breadcrumb" className="migapan verdeclaro">
				<ol className="breadcrumb">
					<li className="breadcrumb-item">
						<Link to={{ pathname: `/${lang}/inicio` }}>
							{loc("NAVBAR-INICIO")}
						</Link>
					</li>
					<li className="breadcrumb-item active" aria-current="page">
						{loc("NAVBAR-CURSOS-VERANO")}
					</li>
				</ol>
			</nav>

			<main>
				<div className="col-12 intsection ampliado ">
					<h2 style={{ textAlign: "left!important", minWidth: "100%" }}>
						{loc("CURSOS-VERANO-INFO1-TITULO")}
					</h2>
					<p style={{ textAlign: "left!important", minWidth: "100%" }}>
						{loc("CURSOS-VERANO-INFO1-DESCRIPCION")}
					</p>
				</div>
				<div className="col-12 intsection cartelesverano">
					{/* <img src={cartel1} alt="" className="cursoverano" onClick={()=>setShow({show:true, curso:1, img:cartel1})}/> */}
					<div className="anima">
						<img
							src={cartel2}
							alt=""
							className="cursoverano visible1"
							onClick={() => setShow({ show: true, curso: 0, img: cartel2 })}
							ref={(el) => (refArr.current[0] = el)}
						/>
					</div>
					<div className="anima">
						<img
							src={cartel3}
							alt=""
							className="cursoverano visible2"
							onClick={() => setShow({ show: true, curso: 1, img: cartel3 })}
							ref={(el) => (refArr.current[1] = el)}
						/>
					</div>
					<div className="anima">
						<img
							src={cartel4}
							alt=""
							className="cursoverano visible3"
							onClick={() => setShow({ show: true, curso: 2, img: cartel4 })}
							ref={(el) => (refArr.current[2] = el)}
						/>
					</div>
					<div className="anima">
						<img
							src={cartel5}
							alt=""
							className="cursoverano visible4"
							onClick={() => setShow({ show: true, curso: 3, img: cartel5 })}
							ref={(el) => (refArr.current[3] = el)}
						/>
					</div>
					<div className="anima">
						<img
							src={cartel6}
							alt=""
							className="cursoverano visible5"
							onClick={() => setShow({ show: true, curso: 4, img: cartel6 })}
							ref={(el) => (refArr.current[4] = el)}
						/>
					</div>
					<div className="anima">
						<img
							src={cartel7}
							alt=""
							className="cursoverano visible6"
							onClick={() => setShow({ show: true, curso: 5, img: cartel7 })}
							ref={(el) => (refArr.current[5] = el)}
						/>
					</div>
					<div className="anima">
						<img
							src={cartel8}
							alt=""
							className="cursoverano visible7"
							onClick={() => setShow({ show: true, curso: 6, img: cartel8 })}
							ref={(el) => (refArr.current[6] = el)}
						/>
					</div>
					<div className="anima">
						<img
							src={cartel9}
							alt=""
							className="cursoverano visible8"
							onClick={() => setShow({ show: true, curso: 7, img: cartel9 })}
							ref={(el) => (refArr.current[7] = el)}
						/>
					</div>
					<div className="anima">
						<img
							src={cartel10}
							alt=""
							className="cursoverano visible9"
							onClick={() => setShow({ show: true, curso: 8, img: cartel10 })}
							ref={(el) => (refArr.current[8] = el)}
						/>
					</div>
				</div>
			</main>
		</>
	);
};
