import React, { useContext, useEffect } from 'react'
import { Link, useParams } from 'react-router-dom';

import img4 from '../assets/img/4.png'

//CONTEXT
import ConexContext from '../context/ConexContext'

export const PTGAS = () => {
	const { lang } = useParams();//parametros de la url
	const { loc }=useContext(ConexContext)//contexto

	useEffect(() => {
		document.title = "PTGAS";
	}, []);

	return (
		<>
			<div className="ptgas">
				{/* <div className="col-12 volver">
					<div className="col-12 contvolver">
						<Link to={{ pathname: `/${lang}/inicio` }} alt="volver al inicio">
							{loc("Volver")}
						</Link>
					</div>
					<a href="#main-content" className="oculto">
						Saltar al contenido principal
					</a>
				</div> */}
				<div className="col-12 contslider">
					<div className="col-12 info">
						<div className="col-6">
							<h1>{loc("PTGAS-TITULO")}</h1>
							<p>{loc("PTGAS-SUBTITULO")}</p>
						</div>
						<div className="col-6">
							<img src={img4} alt="PTGAS" className="sliderimg" />
						</div>
					</div>
				</div>
			</div>

			<nav aria-label="breadcrumb" className="migapan gris">
				<ol className="breadcrumb">
					<li className="breadcrumb-item">
						<Link to={{ pathname: `/${lang}/inicio` }}>
							{loc("NAVBAR-INICIO")}
						</Link>
					</li>
					<li className="breadcrumb-item active" aria-current="page">
						{loc("NAVBAR-PTGAS")}
					</li>
				</ol>
			</nav>

			<main>
				<div className="col-12 intsection">
					<div className="flex-column">
						<h2>{loc("PTGAS-INFO1-TITULO")}</h2>
						<p>{loc("PTGAS-INFO1-SUBTITULO")}</p>
					</div>
				</div>
			</main>
		</>
	);
}
