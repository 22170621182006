import React, { useEffect } from 'react';

export const CookiesProp = () => {

useEffect(() => {
	document.title = "Política de cookies";
}, []);

return (
	<>
	<main>
		<div className="col-12 intsection cookiespage">
			<h2 className=''>Política de cookies</h2>
			<div className="col-12 fixpadding">
				<p>La Universidad de Jaén se ha comprometido a hacer accesibles sus sitios web, de conformidad con el Real Decreto 1112/2018, de 7 de septiembre, sobre accesibilidad de los sitios web y aplicaciones para dispositivos móviles del sector público.</p>
				<p>La presente declaración de accesibilidad se aplica al sitio</p>				
			</div>
			<h2 className=''>¿Qué son las cookies?</h2>
			<div className="col-12 fixpadding">
				<p>La Universidad de Jaén se ha comprometido a hacer accesibles sus sitios web, de conformidad con el Real Decreto 1112/2018, de 7 de septiembre, sobre accesibilidad de los sitios web y aplicaciones para dispositivos móviles del sector público.</p>
				<p>Este sitio web utiliza cookies y/o tecnologías similares que almacenan y recuperan información cuando navegas. En general, estas tecnologías pueden servir para finalidades muy diversas, como, por ejemplo, reconocerte como usuario, obtener información sobre tus hábitos de navegación, o personalizar la forma en que se muestra el contenido.</p>
			</div>
			<h2 className=''>Tipos de cookies</h2>
			<div className="col-12 fixpadding">
				<p>A continuación se realiza una clasificación de los distintos tipos de cookies:</p>
				{/* <h4 className=''>Tipos de cookies según la entidad que la gestione:</h4> */}
				<div className="col-12 fixpadding">
					<p><b>Cookies propias</b>: son aquellas que se envían al equipo terminal del usuario desde un equipo o dominio gestionado por el propio editor y desde el que se presta el servicio solicitado por el usuario.</p>
					<p><b>Cookies de terceros</b>: son aquellas que se envían al equipo terminal del usuario desde un equipo o dominio que no es gestionado por el editor, sino por otra entidad que trata los datos obtenidos través de las cookies</p>
				</div>
				<h4 className=''>Tipos de cookies según su finalidad:</h4>
				<div className="col-12 fixpadding">
				<p><b>Cookies técnicas</b>: son aquellas que permiten al usuario la navegación a través de una página web, plataforma o aplicación y la utilización de las diferentes opciones o servicios que en ella existan, incluyendo aquellas que el editor utiliza para permitir la gestión y operativa de la página web y habilitar sus funciones y servicios, como, por ejemplo, controlar el tráfico y la comunicación de datos, identificar la sesión, acceder a partes de acceso restringido, recordar los elementos que integran un pedido, realizar el proceso de compra de un pedido, gestionar el pago, controlar el fraude vinculado a la seguridad del servicio, realizar la solicitud de inscripción o participación en un evento, contar visitas a efectos de la facturación de licencias del software con el que funciona el servicio (sitio web, plataforma o aplicación), utilizar elementos de seguridad durante la navegación, almacenar contenidos para la difusión de vídeos o sonido, habilitar contenidos dinámicos (por ejemplo, animación de carga de un texto o imagen) o compartir contenidos a través de redes sociales. También pertenecen a esta categoría, por su naturaleza técnica, aquellas cookies que permiten la gestión, de la forma más eficaz posible, de los espacios publicitarios que, como un elemento más de diseño o “maquetación” del servicio ofrecido al usuario, el editor haya incluido en una página web, aplicación o plataforma en base a criterios como el contenido editado, sin que se recopile información de los usuarios con fines distintos, como puede ser personalizar ese contenido publicitario u otros contenidos.</p>
				<p><b>Cookies de preferencias o personalización</b>: son aquellas que permiten recordar información para que el usuario acceda al servicio con determinadas características que pueden diferenciar su experiencia de la de otros usuarios, como, por ejemplo, el idioma, el número de resultados a mostrar cuando el usuario realiza una búsqueda, el aspecto o contenido del servicio en función del tipo de navegador a través del cual el usuario accede al servicio o de la región desde la que accede al servicio, etc.</p>
				<p><b>Cookies de análisis o medición</b>: son aquellas que permiten al responsable de las mismas el seguimiento y análisis del comportamiento de los usuarios de los sitios web a los que están vinculadas, incluida la cuantificación de los impactos de los anuncios. La información recogida mediante este tipo de cookies se utiliza en la medición de la actividad de los sitios web, aplicación o plataforma, con el fin de introducir mejoras en función del análisis de los datos de uso que hacen los usuarios del servicio.</p>
				<p><b>Cookies de publicidad comportamental</b>: son aquellas que almacenan información del comportamiento de los usuarios obtenida a través de la observación continuada de sus hábitos de navegación, lo que permite desarrollar un perfil específico para mostrar publicidad en función del mismo.</p>
				</div>
			</div>
			<h2 className=''>Tipos de cookies según el plazo de tiempo que permanecen activadas:</h2>
			<div className="col-12 fixpadding">
				<p><b>Cookies de sesión</b>: son aquellas diseñadas para recabar y almacenar datos mientras el usuario accede a una página web. Se suelen emplear para almacenar información que solo interesa conservar para la prestación del servicio solicitado por el usuario en una sola ocasión (por ejemplo, una lista de productos adquiridos) y desaparecen al terminar la sesión.</p>
				<p><b>Cookies persistentes</b>: son aquellas en las que los datos siguen almacenados en el terminal y pueden ser accedidos y tratados durante un periodo definido por el responsable de la cookie, y que puede ir de unos minutos a varios años.</p>
				<p>Usos concretos que hacemos de estas tecnologías se describen a continuación.</p>
			</div>
			<h2 className=''>¿Qué tipos de cookies se utilizan en esta página web?</h2>
			<div className="col-12 fixpadding">
				<p>Este sitio web utiliza las siguientes cookies:</p>
				<p><b>Cookies propias</b></p>
				<p>Actualmente no se usan cookies propias.</p>
				<p><b>Cookies de terceros</b></p>
				<ul>
					<li><p>_ga: Google	Cookie de análisis creada por la herramienta Google Analytics. Se utiliza para cuantificar el número de usuarios, realizar la medición y análisis estadístico. Tiene un duración de dos años.</p></li>
					<li><p>_gid: Google Cookie de análisis creada por la herramienta Google Analytics. Se utiliza para cuantificar el número de usuarios, realizar la medición y análisis estadístico. Tiene una duración de 24 horas.</p></li>
					<li><p>_gat: Google Cookie de análisis creada por la herramienta Google Analytics. Se utiliza para cuantificar el número de usuarios, realizar la medición y análisis estadístico. Tiene una duración un minuto.</p></li>
					<li><p>pref: Cookie Utilizada por YouTube. Esta cookie almacena sus preferencias y otra información como idioma usado así como busquedas realizadas. Tiene una duración de un año.</p></li>
				</ul>
			</div>
			<h2 className=''>Licitud del tratamiento</h2>
			<div className="col-12 fixpadding">
			<p>La base legal del tratamiento es su consentimiento (libre, específico, informado e inequívoco). Podrá revocar su consentimiento, siga las instrucciones del siguiente apartado.</p>
			</div>
			<h2 className=''>Forma de aceptar, denegar, revocar el consentimiento o eliminar las cookies</h2>
			<div className="col-12 fixpadding">
			<p>Puede usted permitir o bloquear las cookies, así como borrar sus datos de navegación (incluidas las cookies) desde el navegador que usted utiliza. Consulte las opciones e instrucciones que ofrece su navegador para ello. Tenga en cuenta que, si acepta las cookies de terceros, deberá eliminarlas desde las opciones del navegador.</p>
				<ul>
					<li dir="ltr"><a href="http://support.mozilla.org/es/kb/habilitar-y-deshabilitar-cookies-que-los-sitios-we "><p>Firefox</p></a></li>
					<li dir="ltr"><a href="http://support.google.com/chrome/bin/answer.py?hl=es&amp;answer=95647 "><p>Chrome</p></a></li>
					<li dir="ltr"><a href="https://support.microsoft.com/es-es/help/17442 "><p>Microsoft Explorer</p></a></li>
					<li dir="ltr"><a href="https://support.microsoft.com/es-es/help/4468242/microsoft-edge-browsing-data-and-privacy-microsoft-privacy"><p>Microsoft Edge</p></a></li>
					<li dir="ltr"><a href="http://support.apple.com/kb/ph5042"><p>Safari</p></a><span> </span></li>
					<li dir="ltr"><a href="http://help.opera.com/Windows/11.50/es-ES/cookies.html"><p>Opera</p></a><span> </span></li>
					<li dir="ltr"><p>Otros navegadores<span>: consulte la documentación del navegador que tenga instalado.</span></p></li>
				</ul>
			</div>
			<h2 className=''>Transferencias internacionales de datos</h2>
			<div className="col-12 fixpadding">
			<p><b>Cookies Propias</b></p>
			<p>Sin transferencias internacionales: la información almacenada no supondrá una transferencia internacional.</p>
			<p><b>Cookies de terceros</b></p>
			<p>Sin transferencias internacionales: la información almacenada no supondrá una transferencia internacional.</p>
			</div>
			<h2 className=''><p>Elaboración de perfiles automatizada con efectos jurídicos</p></h2>
			<div className="col-12 fixpadding">
			<p>Caso de que, con las cookies realicemos análisis de perfiles de forma automatizada y que pueda producir efectos jurídicos, dicha información se detallará en la Política de Privacidad.</p>
			</div>
			<h2 className=''><p>Otra información exigida por el art. 13 del RGPD</p></h2>
			<div className="col-12 fixpadding">
			<p>Formando parte del deber de información exigido por el art. 13 del RGPD, os remitimos a la Política de privacidad para informarle de:</p>
			<ul>
				<li><p>la identidad y los datos de contacto del responsable y, en su caso, de su representante;</p></li>
				<li><p>los datos de contacto del delegado de protección de datos, en su caso;</p></li>
				<li><p>la existencia del derecho a solicitar al responsable del tratamiento el acceso a los datos personales relativos al interesado, y su rectificación o supresión, o la limitación de su tratamiento, o a oponerse al tratamiento, así como el derecho a la portabilidad de los datos;</p></li>
				<li><p>el derecho a presentar una reclamación ante una autoridad de control;</p></li>
				<li><p>la existencia de decisiones automatizas, incluida la elaboración de perfiles, a que se refiere el artículo 22, apartados 1 y 4, y, al menos en tales casos, información significativa sobre la lógica aplicada, así como la importancia y las consecuencias previstas de dicho tratamiento para el interesado.</p></li>
			</ul>
			</div>
		</div>
	</main>
	</>
)
}