// import { useContext, Fragment } from "react";
// import ConexContext from "../context/ConexContext";
import React from "react";
import { Modal } from "react-bootstrap";
import CartelBuilder from "./CartelBuilder";
// import cursosData from "../cursos-pdi.json";

import close_icon from "../assets/img/close.svg";
import iconEdpuzzle from "../assets/img/Edpuzzle.png";
import iconGuiadocente from "../assets/img/icon-pdf.svg";

//helpers
import { formatNumero } from "../helpers/formatNumero";

const ModalCurso = ({ curso, show, onHide, cursosData, json, nombre_CAT }) => {
	// const { loc } = useContext(ConexContext);

	const etiquetasString = curso.etiquetas.reduce((acc, item, index) => {
		const isLast = index === curso.etiquetas.length - 1;
		const separator = isLast
			? ""
			: index === curso.etiquetas.length - 2
			? " y "
			: ", ";
		return acc + item + separator;
	}, "");

	// Recibe un string y devuelve el valor de label del valor value que coincida con el string
	const getLabel = (value) => {
		const options = [
			{
				label: "Presencial",
				value: "P",
			},
			{
				label: "Semipresencial",
				value: "SP",
			},
			{
				label: "Online",
				value: "AD",
			},
			{
				label: "Virtual",
				value: "V",
			},
		];

		const option = options.find((option) => option.value === value);
		return option ? option.label : "";
	};

	if (!curso) return null;

	return (
		<Modal
			show={show}
			onHide={onHide}
			animation={false}
			centered
			size="xl"
			className="modal">
			<Modal.Body>
				<div className="modal-cursos__wrapper">
					<button
						type="button"
						onClick={() => {
							onHide();
						}}
						className="modal-cursos__close">
						<img src={close_icon} alt="cerrar" title="(ESC)" />
					</button>
					<div className="modal-cursos__content-wrapper">
						<div className="modal-cursos__content--top">
							<CartelBuilder curso={curso} json={json} tipos={cursosData["tipos"]} />
							<div className="modal-cursos__container">
								<div className="modal-cursos__content">
									<div className="flex-horizontal space-between">
										<div className="flex-horizontal">
											<div className="modal-cursos__content--ects">
												{curso.ects ? curso.ects : "Sin información"} ECTS
											</div>
											<div className="modal-cursos-hover__hours">
												{curso.ects ? curso.ects * 10 : "Sin informacion"}h
											</div>
											<div className="modal-cursos__content--modalidad">
												{curso.modalidad
													? getLabel(curso.modalidad)
													: "Sin información"}
											</div>
											<div className="modal-cursos__content--guiadocente">
												{curso.guiadocente && (
													<a
														href={curso.guiadocente}
														target="_blank"
														rel="noreferrer">
														<img
															src={iconGuiadocente}
															title="Guía Docente"
															alt="Guía Docente"
														/>
													</a>
												)}
											</div>
											{curso.edpuzzle && (
												<div className="modal-cursos__content--edpuzzle">
													<a
														href={curso.edpuzzle}
														target="_blank"
														rel="noreferrer">
														<img
															src={iconEdpuzzle}
															title="EdPuzzle"
															alt="EdPuzzle"
														/>
													</a>
												</div>
											)}
										</div>
										<div className="flex-horizontal"></div>
									</div>
									<div>
										<h1 className="modal-cursos__content--title">
											{curso.titulo ? curso.titulo : "Sin información"}
										</h1>
										<h2 className="modal-cursos__content--subtitle">
											{curso.subtitulo ? curso.subtitulo : ""}
										</h2>
									</div>
									<div className="modal-cursos__content--price">
										{curso.precio && formatNumero(curso.precio, "€", ".", 2, 2)}
									</div>
									<div className="divider--horizontal mobile-hidden"></div>
									{curso.tipo !== "basico" && 
									<div className="flex-column">
										{curso.fechaPreinscripcion ? (
											<div className="modal-cursos__content--date">
												<span>Fecha de preinscripción:</span>
												<div
													className="modal-cursos__content--date"
													dangerouslySetInnerHTML={{
														__html: curso.fechaPreinscripcion,
													}}></div>
											</div>
										) : (
											<div className="modal-cursos__content--date">
												<span>Fecha de preinscripción: </span>
												<div className="modal-cursos__content--date">
													Sin información
												</div>
											</div>
										)}
										{curso.fecha ? (
											<div className="modal-cursos__content--date">
												<span>Fecha de matriculación:</span>
												<div
													className="modal-cursos__content--date"
													dangerouslySetInnerHTML={{
														__html: curso.fecha,
													}}></div>
											</div>
										) : (
											<div className="modal-cursos__content--date">
												<span>Fecha de matriculación: </span>
												<div className="modal-cursos__content--date">
													Sin información
												</div>
											</div>
										)}
										{curso.fechaImparticion ? (
											<div className="modal-cursos__content--date">
												<span>Fecha de impartición:</span>
												<div
													className="modal-cursos__content--date"
													dangerouslySetInnerHTML={{
														__html: curso.fechaImparticion,
													}}></div>
											</div>
										) : (
											<div className="modal-cursos__content--date">
												<span>Fecha de impartición: </span>
												<div className="modal-cursos__content--date">
													Sin información
												</div>
											</div>
										)}
									</div>
									}
									{(curso.url || curso.edpuzzle) && (
										<div className="button-container mobile-hidden">
											<a
												className={`modal-cursos__cta ${curso.estado === "Finalizado" ? "finished" : ""}`}
												href={curso.url ? (curso.url):(curso.edpuzzle)}
												target="blank"
												rel="nofollow">
												Me interesa
											</a>
										</div>
									)}
								</div>
							</div>
						</div>
						<div className="modal-cursos__content--bottom">
							<div className="modal-cursos__content">
								{curso.descripcion ? (
									<p
										className="modal-cursos__content--description mobile-hidden"
										dangerouslySetInnerHTML={{ __html: curso.descripcion }}></p>
								) : (
									<p className="modal-cursos__content--description mobile-hidden">
										Sin información
									</p>
								)}
								<div className="divider--horizontal"></div>
								<div className="modal-cursos__ponentes--container">
									<>
										Impartido por:{" "}
										<span className="modal-cursos__ponentes">
											{curso.ponente.trim() ? curso.ponente : "Sin información"}
										</span>
									</>
								</div>
								<div className="divider--horizontal mobile-hidden"></div>
								<div className="flex-column">
									{cursosData["tipos"].find((tipo) =>	tipo.nombre.includes(curso.tipo)) &&
									<div className="modal-cursos__taxonomies--container">
										Tipo:{" "}
										<span className="modal-cursos__taxonomies">
											{cursosData["tipos"].find((tipo) =>
												tipo.nombre.includes(curso.tipo)
											)?.label || "Sin información"}
										</span>
									</div>
									}
									{cursosData["categorias"].find((categoria) =>curso.categorias.includes(categoria.nombre)) && 
									<div className="modal-cursos__taxonomies--container">
										{nombre_CAT}:{" "}
										<span className="modal-cursos__taxonomies">
											{cursosData["categorias"].find((categoria) =>
												curso.categorias.includes(categoria.nombre)
											)?.label || "Sin información"}
										</span>
									</div>
									}
									{etiquetasString &&
									<div className="modal-cursos__taxonomies--container">
										<>
											Etiquetas:{" "}
											<div className="modal-cursos__taxonomies">
												{etiquetasString ? etiquetasString : "Sin información"}
											</div>
										</>
									</div>
									}
									{(curso.url || curso.edpuzzle) && (
										<div className="button-container desktop-hidden">
											<a
												className={`modal-cursos__cta ${curso.estado === "Finalizado" ? "finished" : ""}`}
												href={curso.url ? (curso.url):(curso.edpuzzle)}
												target="blank"
												rel="nofollow">
												Me interesa
											</a>
										</div>
									)}
								</div>
							</div>
						</div>
					</div>
				</div>
			</Modal.Body>
		</Modal>
	);
};

export default ModalCurso;
