import React from "react";
import "../assets/css/organigrama-listing.css";
import {ReactComponent as IconPDF } from "../assets/img/icon-pdf.svg";

// Al llamar a este componente se le pasa un objeto data y un string tipo (que será 'normativas' o 'resoluciones')
const NormativasListing = ({ data, tipo }) => {
	const listado = data[tipo] || [];

	return (
		<div className="normativa-listing__wrapper">
			<ul>
				{listado.map(({ nombre, url, fecha }, index) => (
					<li key={index}>
						<div className="normativa-listing__content">
							<div className="flex-row">
								<div className="flex-column">
									<h2>
										<a href={url}>{nombre}</a>
									</h2>
									<span>{fecha}</span>
								</div>
								<a href={url} target="_blank" rel="noopener noreferrer">
									<IconPDF alt="PDF Icon" />
								</a>
							</div>
						</div>
					</li>
				))}
			</ul>
		</div>
	);
};

export default NormativasListing;
